import { useEffect, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { TemplateOutlookSignatureSerconsAkademiya } from "../../../templates/templates-outlook-signature/sercons-akademiya/template-outlook-signature-sercons-akademiya";
import { downloadFile } from "../../../utils/downloadFile";
import { ISignatureProps } from "./../../../utils/type";

export function OutlookSignatureSerconsAkademiya({ 
  inputName,
  inputFamily,
  inputPatronymic,
  inputPosition,
  inputTelephoneExt,
  inputTelephoneMobile,
  inputAddress,
  inputBranch,
  inputTelephone,
  avatarUrl
}: ISignatureProps) {
  const [emailHTML, setEmailHTML] = useState('');
  const placeholderUrl = 'https://api.marketing.serconsrus.ru/wp-content/uploads/2024/08/avatar-2.png';
  const isAvatarUploaded = avatarUrl && avatarUrl !== placeholderUrl;

  useEffect(() => {
    const html = renderToStaticMarkup(
      <TemplateOutlookSignatureSerconsAkademiya
      inputName={inputName}
      inputFamily={inputFamily}
      inputPatronymic={inputPatronymic}
      inputPosition={inputPosition}
      inputTelephone={inputTelephone}
      inputTelephoneExt={inputTelephoneExt}
      inputTelephoneMobile={inputTelephoneMobile}
      inputAddress={inputAddress}
      inputBranch={inputBranch}
      avatarUrl={avatarUrl}
      />
    );
    setEmailHTML(html);
  }, [
    inputName,
    inputFamily,
    inputPatronymic,
    inputPosition,
    inputTelephoneExt,
    inputTelephoneMobile,
    inputAddress,
    inputBranch,
    inputTelephone,
    avatarUrl
  ]);

  const handleDownload = () => {
    if (!isAvatarUploaded) {
      alert('Пожалуйста, загрузите фото перед скачиванием подписи.');
      return;
    }

    downloadFile(emailHTML, 'signature.htm');
  };

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: emailHTML }} />
      <button
        className="btn-download"
        onClick={handleDownload}
        style={{
          cursor: isAvatarUploaded ? 'pointer' : 'not-allowed',
          opacity: isAvatarUploaded ? 1 : 0.5,
        }}
      >
        Скачать
      </button>
    </>
  );
}
