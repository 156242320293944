import { ChangeEvent, useState, useEffect } from "react";
import { useImage } from "../../utils/image-context";
import styles from "./page-signature.module.css";
import OutlookSignatureAll from "../../components/outlook/outlook-signature-all/outlook-signature-all";
import Input from "../../ui-kit/input/input";
import CustomAvatarEditor from "../../components/custom-avatar-editor/custom-avatar-editor";
import { BASE_URL } from "../../api/api-сonfig";

// Конфигурация для всех input полей
const inputsConfig = [
  { label: "Имя", valueKey: "inputName", placeholder: "Имя", type: "text", maxLength: 20 },
  { label: "Фамилия", valueKey: "inputFamily", placeholder: "Фамилия", type: "text", maxLength: 20 },
  { label: "Отчество", valueKey: "inputPatronymic", placeholder: "Отчество", type: "text", maxLength: 20 },
  { label: "Должность", valueKey: "inputPosition", placeholder: "Должность", type: "text", maxLength: 50 },
  { label: "Доб. телефон компании", valueKey: "inputTelephoneExt", placeholder: "Телефон добавочный", type: "number", maxLength: 8 },
  { label: "Мобильный телефон", valueKey: "inputTelephoneMobile", placeholder: "Телефон", type: "number", maxLength: 20 },
];

const PageSignature = () => {
  const [companies, setCompanies] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [addresses, setAddresses] = useState<any[]>([]);
  
  const [formData, setFormData] = useState({
    inputName: "",
    inputFamily: "",
    inputPatronymic: "",
    inputPosition: "",
    inputTelephoneExt: "",
    inputTelephoneMobile: "",
    inputAddress: "",
    inputBranch: "",
    inputTelephone: "",
  });

  const { imageUrl } = useImage();
  const defaultImageUrl = "https://api.marketing.serconsrus.ru/wp-content/uploads/2024/08/avatar-2.png";
  const avatarUrl = imageUrl || defaultImageUrl;

  // Загрузка компаний из API
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch(`${BASE_URL}/wp-json/wp/v2/company`);
        const data = await response.json();
        setCompanies(data);
      } catch (error) {
        console.error("Ошибка при загрузке компаний:", error);
      }
    };

    fetchCompanies();
  }, []);

  // Обработчик выбора компании
  const handleCompanyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const company = e.target.value;
    setSelectedCompany(company);

    const selectedCompanyData = companies.find((companyData) => companyData.title.rendered === company);
    const companyAddresses = selectedCompanyData?.acf["_branch-all"] || [];
    setAddresses(companyAddresses);

    setFormData((prev) => ({ ...prev, inputAddress: "", inputBranch: "", inputTelephone: "" }));
  };

  // Обработчик выбора адреса
  const handleAddressChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedAddress = e.target.value;
    const selectedBranchData = addresses.find((branch) => branch.address === selectedAddress);

    setFormData((prev) => ({
      ...prev,
      inputAddress: selectedAddress,
      inputBranch: selectedBranchData?.branch || "",
      inputTelephone: selectedBranchData?.phone || "",
    }));
  };

  const validateInput = (
    value: string,
    type: 'text' | 'number',
    maxLength: number,
    isAddress: boolean = false,
    inputCity: boolean = false // Новый параметр
  ): string => {
    if (type === 'text') {
      if (inputCity) {
        // Для города разрешаем буквы, цифры, пробелы, дефисы, русские и английские точки, запятые
        return value.replace(/[^a-zA-Zа-яА-Я\s,-.\u002C\u002E]/g, '').slice(0, maxLength);
      }
      if (isAddress) {
        // Для адреса без города разрешаем буквы, цифры, пробелы, дефисы и запятые
        return value.replace(/[^a-zA-Zа-яА-Я0-9\s,-.\u002C\u002E]/g, '').slice(0, maxLength);
      }
      // Для других текстовых полей разрешаем буквы и пробелы
      return value.replace(/[^a-zA-Zа-яА-Я\s-]/g, '').slice(0, maxLength);
    }
  
    if (type === 'number') {
      // Для числовых полей удаляем все, кроме цифр, пробелов, скобок и дефисов
      return value.replace(/[^\d\s()-]/g, '').slice(0, maxLength);
    }
  
    return value;
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    key: string,
    type: 'text' | 'number',
    maxLength: number
  ) => {
    const isAddressField = key === 'inputAddress'; // Проверяем, если это поле адреса
    const isCityField = key === 'inputCity'; // Проверяем, если это поле города
  
    const validatedValue = validateInput(
      event.target.value,
      type,
      maxLength,
      isAddressField,
      isCityField // Передаем флаг для города
    );
  
    setFormData((prevData) => ({
      ...prevData,
      [key]: validatedValue,
    }));
  };


  return (
    <section className={styles.signature}>
      <div className="container">
        <div className={styles.signature__widget}>
          <div className={styles.signature__title}>
            <h1>Персональные данные</h1>
            <a
              className={styles.signature__manual}
              href="https://api.marketing.serconsrus.ru/wp-content/uploads/2024/10/ИНСТРУКЦИЯ_Подпись_сотрудника_в_Outlook.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Инструкция
            </a>
          </div>

          <div className={styles.signature__row}>
            <CustomAvatarEditor />

            <div className={styles.signature__data}>
              <div className={styles.signature__information}>
                {inputsConfig.map(({ label, valueKey, placeholder, type, maxLength }) => (
                  <Input
                    key={valueKey}
                    label={label}
                    value={formData[valueKey as keyof typeof formData]}
                    onChange={(e) =>
                      handleInputChange(e, valueKey, type as "text" | "number", maxLength!)
                    }
                    placeholder={placeholder}
                  />
                ))}

                <label className={styles.option}>
                  <span className={styles.option__text}>Компания:</span>
                  <select
                    className={styles.option__input}
                    value={selectedCompany}
                    onChange={handleCompanyChange}
                  >
                    <option value="" disabled>
                      Выберите компанию
                    </option>
                    {companies.map((company) => (
                      <option key={company.id} value={company.title.rendered}>
                        {company.title.rendered}
                      </option>
                    ))}
                  </select>
                </label>

                {selectedCompany && (
                  <label className={`${styles.option} ${styles["label--column-2"]}`}>
                    <span className={styles.option__text}>Адрес:</span>
                    <select
                      className={styles.option__input}
                      value={formData.inputAddress}
                      onChange={handleAddressChange}
                    >
                      <option value="" disabled>
                        Выберите адрес
                      </option>
                      {addresses.map((addressData, index) => (
                        <option key={index} value={addressData.address}>
                          {addressData.branch}, {addressData.address}
                        </option>
                      ))}
                    </select>
                  </label>
                )}
              </div>
            </div>
          </div>
        </div>
        <OutlookSignatureAll
          {...formData}
          avatarUrl={avatarUrl}
        />
      </div>
    </section>
  );
};

export default PageSignature;