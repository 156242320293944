import styles from "./header.module.css"

import logoSvg from "./../../assets/img/icons/logo.svg"
const Header = () => {
  return (
    <>
      <header className={styles.header}>
        <div className="container">
          <div className={styles.row}>
            <img className={styles.logo} src={logoSvg} alt="логотип серконс маркетинг" />

            <div className={styles.turbo}>
              <p className={styles.turbo__top}>Турбо-ускорение</p>
              <p className={styles.turbo__center}>подписей электронной почты</p>
              <p className={styles.turbo__bottom}>Персонализируйте подписи электронной почты до совершенства</p>
            </div>
          </div>

        </div>
      </header>
    </>
  )
}

export default Header;