import { Html } from '@react-email/components';
import { HeadCustom } from "../components/HeadCustom";
import { ISignatureProps } from "../../../utils/type";

export function TemplateOutlookSignatureSerconsMedSmall({
  inputName,
  inputFamily,
  inputPatronymic,
  inputPosition,
  inputTelephoneExt,
  inputTelephoneMobile,
  inputAddress,
  inputBranch,
  inputTelephone,
}: ISignatureProps) {
  return (
    <Html lang="ru">
      <HeadCustom />
      <table
        className="table__StyledTable-sc-1avdl6r-0"
        cellPadding="0"
        cellSpacing="0"
        border={0}
        style={{
          verticalAlign: '-webkit-baseline-middle',
          fontSize: 'medium',
          fontFamily: 'Arial',
          backgroundColor: "#F1F1F1"
        }}
      >
        <tbody>
          <tr>
            <td style={{ paddingTop: "12px", paddingBottom: "12px", }}>
              <table
                cellPadding="0"
                cellSpacing="0"
                border={0}
              >
                <tbody>
                  <tr>
                    <td width="10" align="center" valign="middle"
                      style={{ fontSize: "1px", backgroundColor: "#00ABAB", color: "#C6152C", width: "10px" }}>0
                    </td>
                    <td width="10" style={{ width: "10px" }}></td>
                    <td style={{ backgroundColor: "#D0D0D0" }}>
                      <table
                        cellPadding="0"
                        cellSpacing="0"
                        border={0}
                      >
                        <tbody>
                          <tr>
                            <td width="30" style={{ width: "30px" }}></td>
                            <td style={{ paddingTop: "16px", paddingBottom: "16px" }}>
                              <table width={320} cellPadding="0" cellSpacing="0" border={0}>
                                <tbody>

                                  {/* ФИО */}
                                  {inputFamily && (
                                    <tr>
                                      <td style={{
                                        margin: "0",
                                        fontSize: "18px",
                                        color: "#000",
                                        fontWeight: "700",
                                        lineHeight: "18px",
                                        fontFamily: "'Arial', sans-serif"
                                      }}>
                                        {inputFamily} <br /> {inputName} {inputPatronymic}
                                      </td>
                                    </tr>
                                  )}

                                  {/* Должность */}
                                  {inputPosition && (
                                    <tr>
                                      <td style={{ paddingTop: "8px", paddingBottom: "14px", }}>
                                        <table
                                          cellPadding="0"
                                          cellSpacing="0"
                                          border={0}
                                          role="presentation" style={{
                                            backgroundColor: "#00ABAB",
                                            fontSize: "14px",
                                            color: "#FFF",
                                            fontFamily: "'Arial', sans-serif"
                                          }}>
                                          <tr>
                                            <td style={{ padding: "2px 4px" }}>
                                              {inputPosition}
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  )}
                                  <tr>
                                    <td>
                                      <table
                                        cellPadding="0"
                                        cellSpacing="0"
                                        border={0}
                                      >
                                        <tbody>
                                          {/* Телефон 1 */}
                                          {inputTelephone && (
                                            <tr>
                                              <td align="left">
                                                <img
                                                  style={{ width: "16px", height: "16px" }}
                                                  width="16"
                                                  height="16"
                                                  src="https://api.marketing.serconsrus.ru/wp-content/uploads/2024/08/icon-tell.png"
                                                  alt="icon"
                                                />
                                              </td>
                                              <td style={{ paddingLeft: "5px", paddingBottom: "4px" }}>
                                                <span style={{
                                                  textDecoration: "none",
                                                  margin: "0",
                                                  fontSize: "12px",
                                                  color: "#000",
                                                  fontFamily: "'Arial', sans-serif",
                                                  lineHeight: "12px"
                                                }}>{inputTelephone} {inputTelephoneExt && `(доб. ${inputTelephoneExt})`}</span>
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <table
                                        cellPadding="0"
                                        cellSpacing="0"
                                        border={0}
                                      >
                                        <tbody>
                                          {/* Телефон 2 */}
                                          {inputTelephoneMobile && (
                                            <tr>
                                              <td align="left">
                                                <img
                                                  style={{ width: "16px", height: "16px" }}
                                                  width="16"
                                                  height="16"
                                                  src="https://api.marketing.serconsrus.ru/wp-content/uploads/2024/08/icon-mob.png"
                                                  alt="icon"
                                                />
                                              </td>
                                              <td style={{ paddingLeft: "5px", paddingBottom: "4px" }}>
                                                <span style={{
                                                  textDecoration: "none",
                                                  margin: "0",
                                                  fontSize: "12px",
                                                  color: "#000",
                                                  fontFamily: "'Arial', sans-serif",
                                                  lineHeight: "12px"
                                                }}>{inputTelephoneMobile}</span>
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <table
                                        cellPadding="0"
                                        cellSpacing="0"
                                        border={0}
                                      >
                                        <tbody>
                                          {/* Адрес 2 */}
                                          {inputAddress && (
                                            <tr>
                                              <td align="left">
                                                <img
                                                  style={{ width: "16px", height: "16px" }}
                                                  width="16"
                                                  height="16"
                                                  src="https://api.marketing.serconsrus.ru/wp-content/uploads/2024/08/icon-map.png"
                                                  alt="icon"
                                                />
                                              </td>
                                              <td style={{ paddingLeft: "5px", paddingBottom: "4px" }}>
                                                <span style={{
                                                  textDecoration: "none",
                                                  margin: "0",
                                                  fontSize: "12px",
                                                  color: "#000",
                                                  fontFamily: "'Arial', sans-serif",
                                                  lineHeight: "12px"
                                                }}>{inputBranch}, {inputAddress}</span>
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td width="30" style={{ width: "30px" }}></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td width="30" style={{ width: "30px" }}></td>
                    <td>
                      <table cellPadding="0" cellSpacing="0" border={0}>
                        <tbody>
                          <tr>
                            <td>
                              <a href="https://www.serconsrus.ru/" target="_blank">
                                <img src="https://api.marketing.serconsrus.ru/wp-content/uploads/2024/08/logo-serconsmed.png"
                                  alt="logo"
                                  width="180"
                                  height="22"
                                />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td align="right" style={{ paddingTop: "70px" }}>
                              <img
                                src="https://api.marketing.serconsrus.ru/wp-content/uploads/2024/08/link-icon-serconsmed.png"
                                alt="telephone" width="20" height="24"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td align="right">
                              <a href="https://serconsmed.com/"
                                target="_blank"
                                style={{
                                  color: "#444",
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  textDecoration: "underline",
                                  fontFamily: "'Arial', sans-serif"
                                }}>serconsmed.com</a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td width="30" style={{ width: "30px" }}></td>
                    <td width="10" align="center" valign="middle"
                      style={{ fontSize: "1px", backgroundColor: "#00ABAB", color: "#C6152C", width: "10px" }}>0
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </Html>
  );
}

