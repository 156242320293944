import { useEffect, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { TemplateOutlookSignaturePromashtestRegionySmall } from "../../../templates/templates-outlook-signature/promashtest-regiony/template-outlook-signature-promashtest-regiony-small";

import { ISignatureProps } from "./../../../utils/type";

export function OutlookSignaturePromashtestRegionySmall({ 
  inputName,
  inputFamily,
  inputPatronymic,
  inputPosition,
  inputTelephoneExt,
  inputTelephoneMobile,
  inputAddress,
  inputBranch,
  inputTelephone,
}: ISignatureProps) {
  const [emailHTML, setEmailHTML] = useState('');

  useEffect(() => {
    const html = renderToStaticMarkup(
      <TemplateOutlookSignaturePromashtestRegionySmall
      inputName={inputName}
      inputFamily={inputFamily}
      inputPatronymic={inputPatronymic}
      inputPosition={inputPosition}
      inputTelephone={inputTelephone}
      inputTelephoneExt={inputTelephoneExt}
      inputTelephoneMobile={inputTelephoneMobile}
      inputAddress={inputAddress}
      inputBranch={inputBranch}
      />
    );
    setEmailHTML(html);
  }, [
    inputName,
    inputFamily,
    inputPatronymic,
    inputPosition,
    inputTelephone,
    inputTelephoneExt,
    inputTelephoneMobile,
    inputAddress,
  ]);

  const handleDownload = () => {
    const blob = new Blob([emailHTML], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `signature.htm`;
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: emailHTML }} />
      <button className="btn-download" onClick={handleDownload}>
        Скачать
      </button>
    </>
  );
}