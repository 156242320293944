import React, {useState, useRef, useEffect} from 'react';
import AvatarEditor from 'react-avatar-editor';
import ModalEditPicture from '../modal-edit-picture/modal-edit-picture';

import styles from './custom-avatar-editor.module.scss';
import PenUrl from './../../assets/img/icons/pen-line.svg';
import TrashUrl from './../../assets/img/icons/trash.svg';
import svgUrl from "../../assets/img/icons/picture.svg";

import {useAuth} from '../../utils/auth-context'; // Подключаем контекст для получения токена
import {useImage} from '../../utils/image-context'; // Импортируем контекст
import {getCroppedImage, handleUpload} from './utils/custom-avatar-editor-utils';

const CustomAvatarEditor = () => {
    const [activeOpenModal, setActiveOpenModal] = useState(false);
    const [preview, setPreview] = useState(null);

    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [croppedImage, setCroppedImage] = useState(null); // Состояние для обрезанного изображения
    const editorRef = useRef(null); // Инициализируем реф как null
    const fileInputRef = useRef(null); // Реф для input type="file"
    const {token} = useAuth(); // Получаем токен через useAuth
    const {setImageUrl} = useImage(); // Получаем setImageUrl из контекста

    const [value, setValue] = useState(0);

    const handleInputChange = (e) => {
        setValue(e.target.value);
        e.target.style.setProperty('--value', `${e.target.value}%`);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        // Проверка на размер файла (не более 8 МБ)
        const maxSizeInMB = 5;
        if (file && file.size > maxSizeInMB * 1024 * 1024) {
            alert(`Размер файла не должен превышать ${maxSizeInMB} МБ. Сервис для сжатия изображений: https://tinypng.com/` );
            return;
        }

        // Проверка на допустимые типы файлов
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/heic', 'image/heif'];
        if (file && !allowedTypes.includes(file.type)) {
            alert('Недопустимый формат файла. Разрешены только png, jpg, jpeg, heic и heif.');
            return;
        }

        if (file) {
            setPreview(URL.createObjectURL(file)); // Устанавливаем превью загруженного изображения
            setActiveOpenModal(true); // Открываем модальное окно для редактирования
        }
    };


    const closeModal = () => {
        setActiveOpenModal(false);
    };

    const handleSave = () => {
        getCroppedImage(editorRef, setPreview, setCroppedImage, closeModal);
    };

    const handleClickHint = () => {
        fileInputRef.current.click(); // Программно вызываем клик на input
    };

    const handleWheel = (e) => {
        // Изменяем масштаб при скролле мыши
        if (e.deltaY > 0) {
            setScale((prevScale) => Math.max(1, prevScale - 1)); // Уменьшаем масштаб
        } else {
            setScale((prevScale) => Math.min(10, prevScale + 1)); // Увеличиваем масштаб
        }
    };

    return (
        <div className={styles.block}>
            <div className={styles.block__user}>
                <input
                    type="file"
                    onChange={handleFileChange}
                    ref={fileInputRef} // Привязываем реф к input
                    id="avatarInput"
                    accept="image/*"
                    style={{display: 'none'}}
                />

                {!croppedImage ? (
                    <div className={styles.block__hint} onClick={handleClickHint}>
                        <p className={styles.block__desc}>
                            Выберите <span>файл</span>
                        </p>
                    </div>
                ) : (
                    <div className={styles.block__avatar}>
                        <label htmlFor="avatarInput" className={styles.block__label}>
                            <img src={croppedImage} className={styles.block__img} alt="user"/>
                        </label>
                    </div>
                )}
            </div>

            <div className={styles.block__panel}>
                <div className={styles.block__load}
                     onClick={() => handleUpload(croppedImage, token, setImageUrl)}>загрузить
                </div>
                <div className={styles.block__btn}
                     onClick={() => {
                         if (croppedImage) {
                             setActiveOpenModal(true);
                         } else {
                             alert('Пожалуйста, загрузите изображение перед редактированием.');
                         }
                     }}>
                    <img src={PenUrl} alt="Редактировать"/>
                </div>
                <div className={styles.block__btn}
                     onClick={() => {
                         if (croppedImage) {
                             // Логика удаления изображения
                             setCroppedImage(null); // Очистка превью
                         } else {
                             alert('Пожалуйста, загрузите изображение перед удалением.');
                         }
                     }}>
                    <img src={TrashUrl} alt="Удалить"/>
                </div>
            </div>

            <p className={styles.block__type}>Разрешенные типы файлов: png, jpg, jpeg.</p>

            <ModalEditPicture title="Редактор изображения" active={activeOpenModal} setActive={closeModal}>
                {preview && (
                    <div className={styles.block__row}>
                        <div className={styles.block__plugin}>
                            <AvatarEditor
                                ref={editorRef}
                                image={preview}
                                border={50}
                                width={400}
                                height={400}
                                borderRadius={200}
                                scale={scale}
                                rotate={rotate}
                                onWheel={handleWheel}
                            />
                        </div>

                        <div className={styles.block__edit}>
                            <p className={styles.block__title}>Меню настройки изображения</p>

                            <div className={styles.block__setting}>
                                <div className={styles.block__range}>

                                    <div className={styles.block__line}>
                                        <input
                                            className={styles.block__slider}
                                            type="range"
                                            min="1"
                                            max="10"
                                            step="1"
                                            value={scale}
                                            onChange={(e) => {
                                                const newValue = parseFloat(e.target.value);
                                                setScale(newValue);
                                                // Вычисляем и устанавливаем CSS-переменную для заполненной части
                                                const percentage = ((newValue - 1) / (10 - 1)) * 100;
                                                e.target.style.setProperty('--value', `${percentage}%`);
                                            }}
                                            style={{'--value': `${((scale - 1) / (10 - 1)) * 100}%`}} // Устанавливаем начальное значение
                                        />
                                        <span className={styles.block__number}>{scale}</span>
                                    </div>

                                    <div className={styles.block__line}>
                                        <input
                                            className={styles.block__slider}
                                            type="range"
                                            min="0"
                                            max="360"
                                            step="10"
                                            value={rotate}
                                            onChange={(e) => {
                                                const newValue = parseFloat(e.target.value);
                                                setRotate(newValue);
                                                // Вычисляем и устанавливаем CSS-переменную для заполненной части
                                                const percentage = (newValue / 360) * 100; // Изменено для нового диапазона
                                                e.target.style.setProperty('--value', `${percentage}%`);
                                            }}
                                            style={{'--value': `${(rotate / 360) * 100}%`}} // Устанавливаем начальное значение
                                        />
                                        <span className={styles.block__number}>{rotate}°</span>
                                    </div>
                                </div>

                                <div className={styles.block__bottom}>
                                    <div className={styles.block__open} onClick={handleClickHint}>
                                        <img className={styles.block__svg} src={svgUrl} alt="picture"/>
                                        <span>Открыть</span>
                                    </div>

                                    <div className={styles.block__save} onClick={handleSave}>Сохранить</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </ModalEditPicture>
        </div>
    );
};

export default CustomAvatarEditor;
